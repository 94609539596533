import { Dispatch } from 'react';
import { CalendarAction } from '../types';
import { AppointmentActionType } from './action';

/**
 * Context store method to open reschedule appointment dialog in calendar
 * @param dispatch Dispatch<CalendarAction>
 * @param payload isOpenRescheduleAppointment
 */
export const setIsOpenRescheduleAppointment = (
  dispatch: Dispatch<CalendarAction>,
  isOpenRescheduleAppointment: boolean
) => {
  dispatch({
    type: AppointmentActionType.SET_OPEN_RESCHEDULE_APPOINTMENT,
    isOpenRescheduleAppointment,
  });
};
