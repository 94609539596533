import { CustomerRecordType, CustomerRegType } from '@smart/adb-shared';
import { Icon, Link, Text } from '@smart/react-components';
import { CustomerHeroBanner } from '@store/customers/defaults';
import { getCustomFormattedDate } from '@ui/library/helpers/date-locale';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import classNames from 'classnames';
import { useCustomerContext } from 'contexts/customer-context';
import { useLanguageContext } from 'contexts/language-context';
import { CustomerDetailsState } from 'pages/customer/details/helper';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { CustomerHeroBannerProps } from './HeroBanner.config';
import './HeroBanner.scss';

const BASE_CLASS = 'adb-hero-banner';

const HeroBanner = ({ navItem, bannerType }: CustomerHeroBannerProps) => {
  const isBannerFull = bannerType === CustomerHeroBanner.FULL;

  const { t } = useTranslation();
  const { locale } = useLanguageContext();

  const { customer } = useCustomerContext();
  const navigate = useNavigate();

  const showLeadFeatures = useFeatureFlag({
    key: 'use-lead-features',
    defaultValue: false,
  });

  const customerAddress =
    customer?.address?.street || customer?.address?.town
      ? `${customer?.address?.street} ${customer?.address?.town}`
      : '-';

  const getCustomerTypeHeading = () => {
    if (customer?.__typename === 'CustomerPrivate')
      return t('customer.search.table.private');

    if (customer?.__typename === 'CustomerBusiness') {
      if (customer?.recordType === CustomerRecordType.Outlet)
        return `${t('customer.search.table.outlet')}:`;

      if (customer?.recordType !== CustomerRecordType.FleetCompany)
        return `${t('customer.search.table.business')}:`;

      if (customer?.recordType === CustomerRecordType.FleetCompany)
        return `${t('customer.search.table.fleet')}:`;
    }

    return '';
  };

  return (
    <div
      className={classNames(BASE_CLASS, {
        [`${BASE_CLASS}--full`]: isBannerFull,
        [`${BASE_CLASS}--compact`]: !isBannerFull,
      })}
    >
      <div
        className={classNames(`${BASE_CLASS}__column`, {
          [`${BASE_CLASS}__column--compact`]: !isBannerFull,
        })}
      >
        {customer?.firstName && customer?.lastName && (
          <Text
            variant="hl-300"
            className={`${BASE_CLASS}__customer-name`}
            as="h1"
          >
            {[customer?.firstName, customer?.lastName].join(' ')}
          </Text>
        )}
        {isBannerFull && (
          <div className={`${BASE_CLASS}__row`}>
            <Text variant="cap-300">{getCustomerTypeHeading()}</Text>
            {customer?.__typename === 'CustomerBusiness' && (
              <Text variant="cap-300" className={`${BASE_CLASS}__row-text`}>
                {customer.companyName}
              </Text>
            )}
            {showLeadFeatures && customer?.regType === CustomerRegType.Lite && (
              <>
                <Text variant="cap-300" className={`${BASE_CLASS}__row-text`}>
                  |
                </Text>
                <Link
                  variant="hyp-lgl"
                  className={`${BASE_CLASS}__row-text`}
                  onClick={(e) => {
                    e.preventDefault();
                    navigate(navItem.path, {
                      state: {
                        convertToFullAccount: true,
                      },
                    } as CustomerDetailsState);
                  }}
                >
                  {t('customer.overview.convert')}
                </Link>
              </>
            )}
          </div>
        )}
      </div>

      <div className={`${BASE_CLASS}__column-wrap`}>
        <div
          className={classNames(`${BASE_CLASS}__column`, {
            [`${BASE_CLASS}__column--compact`]: !isBannerFull,
          })}
        >
          <div className={`${BASE_CLASS}__column-item`} data-testid="phone">
            <Icon icon="call" />
            <Text
              as="span"
              className={`${BASE_CLASS}__column-text`}
              variant={isBannerFull ? 'cap-300' : 'cap-100'}
            >
              {customer?.mobileNumber || '-'}
            </Text>
          </div>

          <div className={`${BASE_CLASS}__column-item`} data-testid="email">
            <Icon icon="newsletter-mail" />
            <Text
              as="span"
              className={`${BASE_CLASS}__column-text`}
              variant={isBannerFull ? 'cap-300' : 'cap-100'}
            >
              {customer?.userId ?? '-'}
            </Text>
          </div>
        </div>

        {isBannerFull && (
          <div className={`${BASE_CLASS}__column`}>
            <div
              className={`${BASE_CLASS}__column-item`}
              data-testid="location"
            >
              <Icon icon="dealer-locator-location" />
              <Text
                as="span"
                className={`${BASE_CLASS}__column-text`}
                variant="cap-300"
              >
                {customerAddress}
              </Text>
            </div>
            {customer?.__typename === 'CustomerPrivate' && (
              <div
                className={`${BASE_CLASS}__column-item`}
                data-testid="dateOfBirth"
              >
                <Icon icon="gift" />
                <Text
                  as="span"
                  className={`${BASE_CLASS}__column-text`}
                  variant="cap-300"
                >
                  {customer?.privateAdditionalInfo?.dateOfBirth
                    ? getCustomFormattedDate(
                        new Date(customer?.privateAdditionalInfo.dateOfBirth),
                        'P',
                        locale
                      )
                    : '-'}
                </Text>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default HeroBanner;
