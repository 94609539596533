import { Tooltip } from '@smart/react-components';
import { PropsWithChildren } from 'react';
import { AdbTooltipProps } from './AdbTooltip.config';

const AdbTooltip = ({
  disabled = false,
  title,
  message,
  className,
  children,
}: PropsWithChildren<AdbTooltipProps>) =>
  !disabled ? (
    <Tooltip trigger={children} className={className} asChild>
      <Tooltip.Headline>{title}</Tooltip.Headline>
      <Tooltip.Text>{message}</Tooltip.Text>
    </Tooltip>
  ) : (
    children
  );

export default AdbTooltip;
