import { Dispatch } from 'react';
import type { CalendarAction } from '../types';
import { DemoCarActionType } from './action';

/**
 * Set currently selected demo car to the context store.
 *  Reset value of the currently selected demo car, if demoCar parameter is undefined.
 *
 * @param dispatch Dispatch<CalendarAction>
 * @param demoCar DemoCar (optional)
 */
export const setCurrentDemoCar = (
  dispatch: Dispatch<CalendarAction>,
  demoCarIndex: number
) => {
  dispatch({
    type: DemoCarActionType.SET_CURRENT_DEMO_CAR,
    selectedCarIndex: demoCarIndex,
  });
};
