import {
  Dropdown,
  ContractType,
  HandoverContractType,
} from '@smart/adb-shared';
import { MarketCode } from '@utils/market/types';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const useMarketSpecificContractOptions = (market: MarketCode) => {
  const [contractOptions, setContractOptions] = useState<Dropdown[]>([]);
  const { t } = useTranslation();

  const getFilterOptions = useCallback(
    (filters: any) => {
      const formateFilter = Object.keys(filters).map((data: string) => ({
        label: t(
          `form_fields.documents.type.${data.toLowerCase().replace(/ /g, '_')}`
        ),
        value: filters[data as keyof typeof filters],
      }));
      formateFilter.unshift({ label: '', value: '' });
      return formateFilter;
    },
    [t]
  );

  useEffect(() => {
    switch (market) {
      case 'gb':
        setContractOptions(getFilterOptions(HandoverContractType));
        break;
      default:
        setContractOptions(getFilterOptions(ContractType));
        break;
    }
  }, [t, market, getFilterOptions]);

  return contractOptions;
};
