import { CalendarContextBaseState } from './types';

/**
 * initial state for CalendarContextProvider
 */
export const calendarContextInitialState: CalendarContextBaseState = {
  isLoading: false,
  appointments: [],
  experts: [],
  currentExpert: undefined,
  demoCars: [],
  selectedCarIndex: 0,
  language: '',
  hasFilterChanged: false,
  isOpenRescheduleAppointment: false,
  notification: undefined,
  outlet: undefined,
  isActionLoading: false,
  locale: 'en-US',
};
