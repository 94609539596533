import { Button, TextInput } from '@smart/react-components';
import { useNotificationContext } from 'contexts/notification-context';
import { ChangeEvent, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import './FileUpload.scss';

const BASE_CLASS = 'file-upload';

const getFileExtension = (name: string) => name.split('/').pop() || '';

interface FileUploadProps {
  onChange: (file: File) => void;
  label: string;
  allowedFileExtensions: string[];
  maxFileSizeInBytes: number;
}

const FileUpload = ({
  allowedFileExtensions,
  maxFileSizeInBytes,
  label,
  onChange,
}: FileUploadProps) => {
  const { t } = useTranslation();
  const { addError } = useNotificationContext();
  const [fileData, setFileData] = useState<File>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const selectedFile = event.target.files[0];
      const fileExtension = getFileExtension(selectedFile.type);

      if (!allowedFileExtensions.includes(fileExtension)) {
        addError({
          label: t('customer.documents.notification.error_title'),
          message: t('customer.documents.notification.file_type_not_supported'),
        });
        return;
      }

      if (selectedFile.size >= maxFileSizeInBytes) {
        addError({
          label: t('customer.documents.notification.error_title'),
          message: t('customer.documents.notification.maximum_file_size'),
        });
        return;
      }

      setFileData(selectedFile);
      onChange(selectedFile);
    }
  };

  return (
    <div className={BASE_CLASS}>
      <input
        type="file"
        style={{ display: 'none' }}
        ref={fileInputRef}
        accept={`.${allowedFileExtensions.join(',.')}`}
        onChange={handleFileChange}
      />
      <TextInput disabled value={fileData?.name ?? '-'} label={label} />
      <Button
        mode={200}
        variant="secondary"
        onClick={() => fileInputRef.current?.click()}
        className={`${BASE_CLASS}__attach-btn`}
      >
        {t('customer.documents.upload.attach_file')}
      </Button>
    </div>
  );
};

export default FileUpload;
