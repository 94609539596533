import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import TaskItems from '@smart/components-adb/molecules/TaskItem/TaskItems';
import { Button } from '@smart/react-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useAgentContext } from 'contexts/agent-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useTranslation } from 'react-i18next';
import {
  filterNonServiceOrHandoverAppointments,
  mapAppointments,
} from '../config';
import './to-dos.scss';

const BASE_CLASS = 'adb-todos';
const ToDos = () => {
  const { t } = useTranslation();
  const { agent } = useAgentContext();

  const {
    data: appointments,
    loading: appointmentsLoading,
    refetch: refetchAppointments,
  } = useAllAppointmentsQuery({
    variables: {
      input: {
        endDateTime: getDateXMonthsAfter(2).toISOString(),
        outletId: agent?.outletId ?? '',
        startDateTime: getDateXMonthsBefore(2).toISOString(),
        agentUUID: agent?.id,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !agent?.outletId || !agent?.id,
  });

  const filteredAppointments = mapAppointments(
    filterNonServiceOrHandoverAppointments(
      appointments?.allAppointments.appointments ?? []
    )
  );
  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Content>
        {!appointmentsLoading && filteredAppointments.length > 0 ? (
          <>
            <div className={`${BASE_CLASS}__refresh-btn`}>
              <Button
                variant="ghost"
                mode={200}
                type="button"
                onClick={() => refetchAppointments()}
                className={`${BASE_CLASS}__btn-text`}
              >
                <Button.Icon icon="refresh" />
                {t('customer.documents.buttons.refresh')}
              </Button>
            </div>
            <div className={`${BASE_CLASS}__items`}>
              <TaskItems
                combinedRequests={filteredAppointments}
                referrerPath="/tasks/todos"
              />
            </div>
          </>
        ) : (
          <LoadingIndicator loading={appointmentsLoading} onFullPage>
            <EmptyLayout
              message={t('general.error_messages.no_records_found')}
            />
          </LoadingIndicator>
        )}
      </AdbLayout.Content>
    </div>
  );
};
export default ToDos;
