import { Appointment } from '@smart/adb-shared';
import { MarketCode, marketCodeTimeZoneMap } from '@utils/market/types';
import { set } from 'date-fns';
import { utcToZonedTime } from 'date-fns-tz';

export const BASE_CLASS = 'smart-calendar';

export const BASE_CLASS_CREATE_APPOINTMENT = `${BASE_CLASS}-create-appointment`;

export const getBusinessHours = (marketCode: MarketCode, dateTime: Date) => {
  const timeZone =
    marketCodeTimeZoneMap[marketCode] || marketCodeTimeZoneMap.default;

  const min = set(utcToZonedTime(dateTime, timeZone), {
    hours: 6,
    minutes: 0,
    seconds: 0,
  });
  const max = set(utcToZonedTime(dateTime, timeZone), {
    hours: 23,
    minutes: 0,
    seconds: 0,
  });

  return {
    min,
    max,
  };
};

export interface CalendarAppointment
  extends Omit<Appointment, 'start' | 'end'> {
  start: Date;
  end: Date;
}

export const BusinessHours = {
  min: new Date(0, 0, 0, 6, 0, 0),
  max: new Date(0, 0, 0, 23, 0, 0),
};
