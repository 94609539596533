import { ApolloError } from '@apollo/client';
import {
  AllAppointmentsInput,
  AppointmentStatus,
  AppointmentType,
  CarStatus,
  ExtendedCar,
  Outlet,
} from '@smart/adb-shared';
import AdbLink from '@smart/components-adb/atoms/AdbLink/AdbLink';
import Badge from '@smart/components-adb/atoms/Badge/Badge';
import { BadgeThemes } from '@smart/components-adb/atoms/Badge/Badge.config';
import Dialog from '@smart/components-adb/molecules/Dialog/Dialog';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Flex, Icon, Text } from '@smart/react-components';
import { setIsOpenRescheduleAppointment } from '@store/calendar/appointment';
import {
  getCustomFormattedDate,
  setHour,
} from '@ui/library/helpers/date-locale';
import { updateAppointmentInGqlCache } from '@utils/helpers/gqlCacheHelper';
import { getCustomerRoute } from '@utils/helpers/route';
import classnames from 'classnames';
import { useAgentContext } from 'contexts/agent-context';
import { useCalendarContext } from 'contexts/calendar-context';
import { useLanguageContext } from 'contexts/language-context';
import { useNotificationContext } from 'contexts/notification-context';
import {
  useCompleteAppointmentMutation,
  useConfirmAppointmentMutation,
  useStartAppointmentMutation,
} from 'graphql/queries/appointments.generated';
import { AllTasksDocument } from 'graphql/queries/tasks.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutlet } from 'hooks/outlet';
import { useAppointmentLazyQuery } from 'pages/tasks/tasks/queries.generated';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BASE_CLASS, CalendarAppointment } from '../config';
import { AppointmentDialogProps } from './AppointmentDialog.config';
import './AppointmentDialog.scss';
import CancelDialog from './CancelDialog';

const BASE_CLASS_APPOINTMENT_DIALOG = `${BASE_CLASS}__dialog`;

const DialogHeader = ({
  outlet,
  appointment,
  demoCar,
}: {
  outlet: Outlet | undefined;
  appointment: CalendarAppointment;
  demoCar: ExtendedCar | undefined;
}) => {
  const { t } = useTranslation();
  return (
    <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__header`}>
      <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__header-content`}>
        {appointment.type !== AppointmentType.Handover && (
          <Text
            variant="cap-300"
            as="p"
            className={`${BASE_CLASS_APPOINTMENT_DIALOG}__header-content-location`}
          >
            {outlet && outlet.name}
          </Text>
        )}

        {appointment.title && (
          <Text variant="hl-100" as="h1" test-id="heading-component">
            {t(appointment.title)}
          </Text>
        )}

        <Text variant="hl-100" as="h1" test-id="heading-component">
          {appointment.type === AppointmentType.Handover
            ? appointment.car?.name
            : demoCar?.name}
        </Text>
        {appointment.type !== AppointmentType.ServiceAppointment && (
          <Text
            className={`${BASE_CLASS_APPOINTMENT_DIALOG}__header-content-agent`}
            variant="cap-300"
            as="p"
          >
            {`${t('feature_calendar.general.smart_expert')}: `}{' '}
            {appointment.expert?.id !== ''
              ? appointment.expert?.fullName
              : t('feature_calendar.general.no_expert')}
          </Text>
        )}
      </div>
      <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__header-status`}>
        {appointment.status !== AppointmentStatus.Requested && (
          <Flex alignItems="center" direction="row" gap={100}>
            <Badge theme={BadgeThemes.SUCCESS} />
            {t('feature_calendar.general.buttons.confirmed')}
          </Flex>
        )}
      </div>
    </div>
  );
};

const DialogBody = ({
  appointment,
  demoCar,
}: {
  appointment: CalendarAppointment;
  demoCar: ExtendedCar | undefined;
}) => {
  const { t } = useTranslation();
  const { locale } = useLanguageContext();
  const outlet = useCurrentOutlet();
  return (
    <>
      <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body`}>
        <div
          className={classnames(
            `${BASE_CLASS_APPOINTMENT_DIALOG}__body-section`,
            {
              [CarStatus.Inactive]: demoCar?.status === CarStatus.Inactive,
            }
          )}
        >
          <Icon icon="car" mode={300} />
          <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section-car`}>
            <Text
              variant="p-100"
              as="p"
              className={classnames({
                [CarStatus.Inactive]: demoCar?.status === CarStatus.Inactive,
              })}
            >
              {appointment.type !== AppointmentType.Handover
                ? demoCar?.features?.exterior
                : appointment.car?.carId}
            </Text>
            <Text
              variant="p-100"
              as="p"
              className={classnames({
                [CarStatus.Inactive]: demoCar?.status === CarStatus.Inactive,
              })}
            >
              {demoCar?.licensePlateNumber}
            </Text>
            {appointment.type === AppointmentType.Handover &&
              (!appointment.car?.licensePlate ||
                !appointment.car?.registrationDate) && (
                <div
                  className={`${BASE_CLASS_APPOINTMENT_DIALOG}__inactive-text`}
                >
                  <Icon icon="warning" />
                  <Text variant="cap-300" as="p">
                    {t('feature_calendar.error_messages.non_infleeted_text')}
                  </Text>
                </div>
              )}
            {demoCar?.status === CarStatus.Inactive && (
              <div
                className={`${BASE_CLASS_APPOINTMENT_DIALOG}__inactive-text`}
              >
                <Icon icon="warning" />
                <Text variant="cap-300" as="p" color="c-high-contrast">
                  {t('feature_calendar.calendar_dialog.deactivated_car_text')}
                </Text>
              </div>
            )}
            {appointment.type !== AppointmentType.ServiceAppointment &&
              appointment.status !== AppointmentStatus.Completed && (
                <div
                  className={`${BASE_CLASS_APPOINTMENT_DIALOG}__inactive-text`}
                >
                  <Icon icon="info-legal" />
                  <Text variant="cap-300" as="p" color="c-high-contrast">
                    {t(
                      'feature_calendar.calendar_dialog.appointment_not_complete'
                    )}
                  </Text>
                </div>
              )}
          </div>
        </div>

        <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section`}>
          <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__space-between`}>
            <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__flex`}>
              <Icon icon="appointment" mode={300} />
              <div
                className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section-time`}
              >
                {new Date(appointment.start).getDate() ===
                new Date(appointment.end).getDate() ? (
                  <>
                    <Text variant="cap-200" as="p">
                      {getCustomFormattedDate(
                        new Date(appointment.start),
                        'eeee',
                        locale
                      )}{' '}
                      {getCustomFormattedDate(
                        new Date(appointment.start),
                        'dd.MM',
                        locale
                      )}
                    </Text>
                    <Text variant="lbl-100" as="p">
                      {`${getCustomFormattedDate(
                        new Date(appointment.start),
                        'HH.mm',
                        locale
                      )}-${getCustomFormattedDate(
                        new Date(appointment.end),
                        'HH.mm',
                        locale
                      )}`}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text variant="cap-200" as="p">
                      {getCustomFormattedDate(
                        new Date(appointment.start),
                        'eeee',
                        locale
                      )}{' '}
                      {getCustomFormattedDate(
                        new Date(appointment.start),
                        'dd.MM',
                        locale
                      )}
                      {' - '}
                      {getCustomFormattedDate(
                        new Date(appointment.end),
                        'eeee',
                        locale
                      )}{' '}
                      {getCustomFormattedDate(
                        new Date(appointment.end),
                        'dd.MM',
                        locale
                      )}
                    </Text>
                    <Text variant="lbl-100" as="p">
                      {`${getCustomFormattedDate(
                        new Date(appointment.start),
                        'HH.mm',
                        locale
                      )}-${getCustomFormattedDate(
                        new Date(appointment.end),
                        'HH.mm',
                        locale
                      )}`}
                    </Text>
                  </>
                )}
              </div>
            </div>
            {appointment.type === AppointmentType.Handover && (
              <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section`}>
                <Icon icon="dealer-locator-location" mode={300} />
                <div
                  className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section-location`}
                >
                  <Text variant="cap-100" as="p">
                    {outlet && outlet.name}
                  </Text>
                </div>
              </div>
            )}
          </div>
        </div>

        <div className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section`}>
          <Icon icon="account" mode={300} />
          <div
            className={`${BASE_CLASS_APPOINTMENT_DIALOG}__body-section-customer`}
          >
            <Text variant="lbl-100" as="p">{`${t(
              'test_drive.summary.customer'
            )}:`}</Text>
            {appointment.customer?.firstName &&
              appointment.customer?.lastName && (
                <AdbLink
                  state={{ key: '/calendar' }}
                  additionalClasses={`${BASE_CLASS}__body-section-customer-name`}
                  path={getCustomerRoute(appointment.customer)}
                  variant="hyperlink-200"
                  title={[
                    appointment.customer.firstName,
                    appointment.customer.lastName,
                  ].join(' ')}
                />
              )}
            {appointment.customer?.mobileNumber && (
              <Text variant="p-100" as="p">
                {appointment?.customer?.mobileNumber}
              </Text>
            )}
            {appointment.customer?.userId && (
              <Text variant="p-100" as="p">
                {appointment.customer.userId}
              </Text>
            )}
          </div>
        </div>
      </div>
      {appointment.status === AppointmentStatus.Completed && (
        <div
          className={`${BASE_CLASS_APPOINTMENT_DIALOG}__test-drive-complete`}
        >
          <Text variant="cap-300" as="p">
            {appointment.type === AppointmentType.TestDrive
              ? `${t(
                  'feature_calendar.calendar_dialog.test_drive_appointment_complete'
                )}`
              : `${t(
                  'feature_calendar.calendar_dialog.handover_appointment_complete'
                )}`}
          </Text>
        </div>
      )}
    </>
  );
};

const AppointmentDialog = ({
  appointment,
  isOpen,
  demoCar,
  setModalVisibility,
  calendarDateRange,
  isOutsideCalendar = false,
}: AppointmentDialogProps) => {
  const { t } = useTranslation();
  const { dispatch, isActionLoading } = useCalendarContext();
  const outlet = useCurrentOutlet();
  const { addSuccess } = useNotificationContext();
  const { agent: loggedInExpert } = useAgentContext();

  const [handoverConfirmOpen, setHandoverConfirmOpen] = useState(false);

  const { registerModal, closeModal } = useModal();

  const handleRescheduleAppointment = () => {
    setModalVisibility(false);
    setIsOpenRescheduleAppointment(dispatch, true);
  };

  const toggleHandoverConfirmOpen = () => {
    setHandoverConfirmOpen(!handoverConfirmOpen);
  };

  const showCancelDialog = (type: 'cancel' | 'decline') => {
    registerModal(
      <CancelDialog
        appointmentId={appointment.id}
        calendarDateRange={calendarDateRange}
        onClose={closeModal}
        onCloseParent={() => setModalVisibility(false)}
        type={type}
      />
    );
  };

  const showGenericError = (error: ApolloError) => {
    enhanceError({
      error,
      label: t('feature_calendar.notification.error_title'),
      displayMessage: t('feature_calendar.notification.error_description'),
    });
  };

  const refetchQueries = [
    {
      query: AllTasksDocument,
      variables: {
        input: {
          outletId: outlet?.mcsId,
        },
      },
      skip: !outlet?.mcsId,
    },
  ];

  const [getAppointment] = useAppointmentLazyQuery();

  const appointmentsInput: AllAppointmentsInput = {
    startDateTime: setHour(calendarDateRange[0], 6),
    endDateTime: setHour(calendarDateRange[1], 23),
    outletId: outlet?.bpId ?? '',
  };

  const [confirmAppointment, { loading: isConfirming }] =
    useConfirmAppointmentMutation({
      variables: {
        input: {
          appointmentId: appointment.id,
          ownerId: loggedInExpert?.id ?? '',
        },
      },
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.confirmAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t('feature_calendar.notification.confirmed_appointment_title'),
          message: t(
            'feature_calendar.notification.confirmed_appointment_description'
          ),
        });
        setModalVisibility(false);
      },
      onError: (error) => {
        showGenericError(error);
        setModalVisibility(false);
      },
      refetchQueries,
    });

  const [startAppointment, { loading: startLoading }] =
    useStartAppointmentMutation({
      variables: {
        input: {
          appointmentId: appointment.id,
          ownerId: loggedInExpert?.id ?? '',
        },
      },
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.startAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t('feature_calendar.notification.started_appointment_title'),
          message: t(
            'feature_calendar.notification.started_appointment_description'
          ),
        });
        setModalVisibility(false);
      },
      onError: (error) => {
        showGenericError(error);
        setModalVisibility(false);
      },
      refetchQueries,
    });

  const [completeAppointment, { loading: completeLoading }] =
    useCompleteAppointmentMutation({
      variables: {
        input: {
          appointmentId: appointment.id,
          ownerId: loggedInExpert?.id ?? '',
        },
      },
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.completeAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t('feature_calendar.notification.returned_appointment_title'),
          message: t(
            'feature_calendar.notification.returned_appointment_description'
          ),
        });
        setModalVisibility(false);
        setHandoverConfirmOpen(false);
      },
      onError: (error) => {
        showGenericError(error);
        setModalVisibility(false);
        setHandoverConfirmOpen(false);
      },
      refetchQueries,
    });

  const completeIsDisabled = !!(
    !appointment.car?.licensePlate || !appointment.car.registrationDate
  );

  const getDialogFooterButtonsConfig = () => {
    if (appointment.type === AppointmentType.ServiceAppointment) {
      return undefined;
    }

    switch (appointment.status) {
      case AppointmentStatus.Scheduled:
        return {
          ...(!isOutsideCalendar && {
            link: {
              label: t('feature_calendar.general.buttons.reschedule'),
              onClick: handleRescheduleAppointment,
            },
          }),
          secondary: {
            label: t('feature_calendar.general.buttons.cancel_scheduled'),
            onClick: () => showCancelDialog('cancel'),
          },
          primary:
            appointment.type === AppointmentType.Handover
              ? {
                  label: t('feature_calendar.general.buttons.complete'),
                  onClick: toggleHandoverConfirmOpen,
                  disabled: completeIsDisabled || isActionLoading,
                  isLoading: isActionLoading,
                }
              : {
                  label: t('feature_calendar.general.buttons.start'),
                  onClick: startAppointment,
                  disabled: startLoading,
                  isLoading: startLoading,
                },
        };
      case AppointmentStatus.InProgress:
        return {
          secondary: {
            label: t('feature_calendar.general.buttons.cancel_scheduled'),
            onClick: () => showCancelDialog('cancel'),
          },
          primary: {
            label: t('feature_calendar.general.buttons.return'),
            onClick: completeAppointment,
            disabled: completeLoading,
            isLoading: completeLoading,
          },
        };
      case AppointmentStatus.Requested:
        return {
          ...(!isOutsideCalendar && {
            link: {
              label: t('feature_calendar.general.buttons.reschedule'),
              onClick: handleRescheduleAppointment,
            },
          }),
          secondary: {
            label: t('feature_calendar.general.buttons.cancel_scheduled'),
            onClick: () => showCancelDialog('decline'),
          },
          primary: {
            label: t('feature_calendar.general.buttons.confirm'),
            onClick: confirmAppointment,
            isLoading: isConfirming,
          },
        };

      default:
        return undefined;
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        setModalVisibility={setModalVisibility}
        header={
          <DialogHeader
            outlet={outlet}
            appointment={appointment}
            demoCar={demoCar}
          />
        }
        content={<DialogBody appointment={appointment} demoCar={demoCar} />}
        buttons={getDialogFooterButtonsConfig()}
        additionalClasses={BASE_CLASS_APPOINTMENT_DIALOG}
        preventCloseOnClickOutside
      />

      <Dialog
        isOpen={handoverConfirmOpen}
        preventCloseOnClickOutside
        header={t(
          'feature_calendar.calendar_dialog.confirm_handover_complete_heading'
        )}
        buttons={{
          primary: {
            label: t('feature_calendar.general.buttons.confirm'),
            onClick: completeAppointment,
            disabled: completeLoading,
            isLoading: completeLoading,
          },
          secondary: {
            label: t('feature_calendar.general.buttons.cancel'),
            onClick: toggleHandoverConfirmOpen,
          },
        }}
        content={t(
          'feature_calendar.calendar_dialog.confirm_handover_complete_description'
        )}
        setModalVisibility={toggleHandoverConfirmOpen}
        additionalClasses="light-theme"
      />
    </>
  );
};

export default AppointmentDialog;
