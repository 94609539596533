import { AllAppointmentsInput, AppointmentStatus } from '@smart/adb-shared';
import AdbLink from '@smart/components-adb/atoms/AdbLink/AdbLink';
import CancelDialog from '@smart/components-adb/calendar/AppointmentDialog/CancelDialog';
import AdbDialog from '@smart/components-adb/molecules/AdbDialog/AdbDialog';
import { Button, Icon, Text } from '@smart/react-components';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { getCustomFormattedDate } from '@ui/library/helpers/date-locale';
import { updateAppointmentInGqlCache } from '@utils/helpers/gqlCacheHelper';
import { getCustomerRoute } from '@utils/helpers/route';
import { useAgentContext } from 'contexts/agent-context';
import { useLanguageContext } from 'contexts/language-context';
import { useNotificationContext } from 'contexts/notification-context';
import { useConfirmAppointmentMutation } from 'graphql/queries/appointments.generated';
import { enhanceError } from 'graphql/reactive-error';
import { useCurrentOutletId } from 'hooks/outlet';
import { useAppointmentLazyQuery } from 'pages/tasks/tasks/queries.generated';
import { useTranslation } from 'react-i18next';
import { useModal } from '../../Modal';
import RescheduleAppointmentForm from '../RescheduleAppointmentForm/RescheduleAppointmentForm';
import type { TestDriveDetailProps } from './AppointmentDetail.config';
import './AppointmentDetail.scss';

const BASE_CLASS = 'adb-tasks-appointment';
const TestDriveDetail = ({
  appointment,
  referrerPath = '',
}: TestDriveDetailProps) => {
  const { t } = useTranslation();
  const { agent: loggedInExpert } = useAgentContext();
  const { addSuccess } = useNotificationContext();
  const outlet = useCurrentOutletId();
  const { locale } = useLanguageContext();
  const { closeModal, registerModal } = useModal();

  const [getAppointment] = useAppointmentLazyQuery();

  const appointmentsInput: AllAppointmentsInput = {
    endDateTime: getDateXMonthsAfter(2).toISOString(),
    outletId: outlet?.bpId ?? '',
    startDateTime: getDateXMonthsBefore(6).toISOString(),
  };

  const [confirmAppointment, { loading: isConfirming }] =
    useConfirmAppointmentMutation({
      variables: {
        input: {
          appointmentId: appointment.id,
          ownerId: loggedInExpert?.id ?? '',
        },
      },
      update: (cache, { data }) =>
        updateAppointmentInGqlCache({
          cache,
          appointmentId: data?.confirmAppointment.appointmentId,
          input: appointmentsInput,
          getAppointment,
        }),
      onCompleted: () => {
        addSuccess({
          label: t('feature_calendar.notification.confirmed_appointment_title'),
          message: t(
            'feature_calendar.notification.confirmed_appointment_description'
          ),
        });
      },
      onError: (error) => {
        enhanceError({
          error,
          label: t('feature_calendar.notification.error_title'),
          displayMessage: t('feature_calendar.notification.error_description'),
        });
      },
    });

  const onConfirmAppointment = () => confirmAppointment();

  const onRescheduleAppointment = () =>
    registerModal(
      <AdbDialog id="reschedule-dialog">
        <AdbDialog.Content>
          <RescheduleAppointmentForm
            appointment={appointment}
            onClose={closeModal}
          />
        </AdbDialog.Content>
      </AdbDialog>
    );

  return (
    <div className={BASE_CLASS}>
      {appointment?.status === AppointmentStatus.Scheduled &&
        appointment?.expert?.fullName && (
          <Text variant="cap-300" as="p" className={`${BASE_CLASS}__agent`}>
            {`${t('task.drop_down.assigned_to')} `}
            {appointment?.expert?.fullName}
          </Text>
        )}
      <div className={`${BASE_CLASS}__content`}>
        <div className={`${BASE_CLASS}__content-time`}>
          <Icon icon="appointment" mode={300} />
          <div className={`${BASE_CLASS}__content-time-details`}>
            <Text variant="lbl-100" as="p">
              {appointment?.start &&
                getCustomFormattedDate(
                  new Date(appointment.start),
                  'eeee, P',
                  locale
                )}
            </Text>
            <Text
              variant="p-100"
              as="p"
              className={`${BASE_CLASS}__content-time-details-text`}
            >
              {appointment?.start &&
                appointment?.end &&
                `${getCustomFormattedDate(
                  new Date(appointment.start),
                  'HH.mm',
                  locale
                )}-${getCustomFormattedDate(
                  new Date(appointment.end),
                  'HH.mm',
                  locale
                )}`}
            </Text>
          </div>
        </div>
        <div className={`${BASE_CLASS}__content-car`}>
          <Icon icon="car" mode={300} />
          <div className={`${BASE_CLASS}__content-car-details`}>
            {appointment.car?.licensePlate && (
              <Text variant="lbl-100" as="p">
                {appointment.car.licensePlate}
              </Text>
            )}
            {appointment.car?.name && (
              <Text
                variant="p-100"
                as="p"
                className={`${BASE_CLASS}__content-car-details-text`}
              >
                {appointment.car.name}
              </Text>
            )}
            {appointment.car?.carId && (
              <Text
                variant="p-100"
                as="p"
                className={`${BASE_CLASS}__content-car-details-text`}
              >
                {appointment.car.carId}
              </Text>
            )}
          </div>
        </div>
        <div className={`${BASE_CLASS}__content-customer`}>
          <Icon icon="account" mode={300} />
          <div className={`${BASE_CLASS}__content-customer-details`}>
            {appointment?.customer?.uuid && (
              <AdbLink
                state={{ key: referrerPath }}
                path={getCustomerRoute(appointment.customer)}
                variant="hyperlink-200"
                title={`${appointment.customer.firstName} ${appointment.customer.lastName}`}
                notLink={!appointment.customer.uuid}
              />
            )}
            {appointment?.customer?.mobileNumber && (
              <Text
                variant="p-100"
                as="p"
                className={`${BASE_CLASS}__content-customer-details-text`}
              >
                {appointment.customer.mobileNumber}
              </Text>
            )}
            {appointment?.customer?.userId && (
              <Text
                variant="p-100"
                as="p"
                className={`${BASE_CLASS}__content-customer-details-text`}
              >
                {appointment.customer.userId}
              </Text>
            )}
          </div>
        </div>
      </div>
      <div className={`${BASE_CLASS}__calendar`}>
        <Button type="button" variant="ghost" onClick={onRescheduleAppointment}>
          <Button.Icon icon="edit" aria-label="edit" />
          {t('feature_calendar.general.buttons.reschedule')}
        </Button>
        <Button
          type="button"
          variant="secondary"
          onClick={() =>
            registerModal(
              <CancelDialog
                appointmentId={appointment.id}
                calendarDateRange={[
                  getDateXMonthsBefore(2),
                  getDateXMonthsAfter(6),
                ]}
                onClose={closeModal}
                type="cancel"
              />
            )
          }
        >
          {t('feature_calendar.general.buttons.cancel_scheduled')}
        </Button>
        {appointment.status === AppointmentStatus.Requested && (
          <Button
            type="button"
            variant="primary"
            onClick={onConfirmAppointment}
            loading={isConfirming}
          >
            <Button.Spinner />
            {t('feature_calendar.general.buttons.confirm')}
          </Button>
        )}
      </div>
    </div>
  );
};

export default TestDriveDetail;
