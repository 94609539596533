import { Routes } from 'routes.config';
import Requests from './requests';
import Tasks from './tasks';
import ToDos from './to-dos';

export const tasks: Routes[] = [
  {
    path: '/tasks',
    component: Tasks,
    title: 'navigation.dashboard.all_tasks',
  },
  {
    path: '/tasks/requests',
    component: Requests,
    title: 'navigation.tasks.requests',
  },
  {
    path: '/tasks/todos',
    component: ToDos,
    title: 'navigation.tasks.tasks',
  },
];
