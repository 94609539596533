import { Button, IconButton, Text } from '@smart/react-components';
import classNames from 'classnames';
import { DialogProps } from './Dialog.config';
import './Dialog.scss';

const BASE_CLASS = 'adb-dialog';

const Dialog = ({
  header,
  content,
  buttons,
  isOpen,
  setModalVisibility,
  additionalClasses,
  preventCloseOnClickOutside,
  isSignDocument,
  isCalendarDialog,
}: DialogProps) => {
  const baseAdditionalClass =
    additionalClasses && additionalClasses.length > 0
      ? additionalClasses.split(' ')[0]
      : '';

  const handleCloseModal = () => setModalVisibility(false);

  const clickOutside = (event: React.MouseEvent) => {
    event.stopPropagation();
    const isOutside = (event.target as HTMLDivElement).classList.contains(
      BASE_CLASS
    );
    if (!preventCloseOnClickOutside && isOutside) {
      handleCloseModal();
    }
  };

  return isOpen ? (
    <div
      className={classNames(BASE_CLASS, baseAdditionalClass)}
      onClick={clickOutside}
      onKeyDown={() => {}}
      tabIndex={0}
      role="button"
    >
      <div
        className={classNames(`${BASE_CLASS}__wrapper`, {
          [`${BASE_CLASS}__wrapper-sign-document`]: isSignDocument,
          [`${BASE_CLASS}__wrapper-calendar-dialog`]: isCalendarDialog,
        })}
      >
        <div className={`${BASE_CLASS}__container`}>
          <div
            className={classNames({
              [`${BASE_CLASS}__header`]: typeof header === 'string',
              [`${BASE_CLASS}__header--with-jsx`]: typeof header !== 'string',
            })}
          >
            {header && typeof header === 'string' ? (
              <Text variant="hl-100" as="h1">
                {header}
              </Text>
            ) : (
              header
            )}
            <IconButton
              data-testid="dialog-close-button"
              variant="ghost"
              onClick={handleCloseModal}
              className={`${BASE_CLASS}__close-btn`}
            >
              <Button.Icon
                icon="close-without-circle"
                aria-label="close-without-circle"
              />
            </IconButton>
          </div>

          {content &&
            (typeof content === 'string' ? (
              <Text variant="cap-100">{content}</Text>
            ) : (
              content
            ))}
          {buttons && (
            <div className={`${BASE_CLASS}__footer`}>
              {buttons?.link?.label && buttons?.link?.onClick && (
                <Button
                  mode={200}
                  variant="ghost"
                  onClick={buttons.link.onClick}
                >
                  <Button.Icon icon="edit" />
                  {buttons.link.label}
                </Button>
              )}
              {buttons?.secondary?.label && buttons?.secondary?.onClick && (
                <Button
                  variant="secondary"
                  mode={200}
                  onClick={buttons.secondary.onClick}
                >
                  {buttons.secondary.label}
                </Button>
              )}
              {buttons?.primary?.label && buttons?.primary?.onClick && (
                <Button
                  variant="primary"
                  mode={200}
                  onClick={buttons.primary.onClick}
                  disabled={buttons.primary.disabled}
                  loading={buttons.primary.isLoading}
                >
                  <Button.Spinner />
                  {buttons.primary.label}
                </Button>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  ) : null;
};

export default Dialog;
