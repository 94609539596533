import { AppointmentStatus, AppointmentType } from '@smart/adb-shared';
import EmptyLayout from '@smart/components-adb/atoms/EmptyDataLayout/EmptyLayout';
import LoadingIndicator from '@smart/components-adb/atoms/LoadingIndicator/LoadingIndicator';
import TaskItems from '@smart/components-adb/molecules/TaskItem/TaskItems';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
} from '@ui/library/helpers/date';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  filterAppointments,
  mapAppointments,
  TaskFilterPriority,
  TaskFilterType,
} from '../config';
import TaskSearch from '../search/search';
import './requests.scss';

const BASE_CLASS = 'adb-open-requests';
const Requests = () => {
  const { t } = useTranslation();
  const outlet = useCurrentOutlet();
  const [currentSearch, setCurrentSearch] = useState<string>('');
  const [filterQuery, setFilterQuery] = useState<
    TaskFilterPriority | TaskFilterType
  >(TaskFilterType.ALL);
  const onSearchQueryUpdate = (
    textSearch: string,
    filter?: TaskFilterPriority | TaskFilterType
  ): void => {
    setCurrentSearch(textSearch);
    if (filter) setFilterQuery(filter);
  };

  const {
    data: appointments,
    loading: appointmentsLoading,
    refetch: refetchAppointments,
  } = useAllAppointmentsQuery({
    variables: {
      input: {
        startDateTime: getDateXMonthsBefore(2).toISOString(),
        endDateTime: getDateXMonthsAfter(6).toISOString(),
        outletId: outlet?.bpId ?? '',
        appointmentType: AppointmentType.TestDrive,
        appointmentStatus: AppointmentStatus.Requested,
      },
    },
    notifyOnNetworkStatusChange: true,
    skip: !outlet?.bpId,
  });

  const nonConfirmedAppointments =
    appointments?.allAppointments.appointments ?? [];

  const filteredAppointments = mapAppointments(
    filterAppointments(nonConfirmedAppointments, currentSearch, filterQuery)
  ).sort(
    (firstTask, secondTask) =>
      new Date(secondTask.start).getTime() - new Date(firstTask.start).getTime()
  );

  return (
    <div className={BASE_CLASS}>
      <AdbLayout.Header backgroundColor="bg-level-2">
        <TaskSearch
          refetch={refetchAppointments}
          onSearchQueryUpdate={onSearchQueryUpdate}
          rows={filteredAppointments.length}
          currentFilter={filterQuery}
        />
      </AdbLayout.Header>
      <AdbLayout.Content>
        {!appointmentsLoading && filteredAppointments.length > 0 ? (
          <div className={`${BASE_CLASS}__items`}>
            <TaskItems
              combinedRequests={filteredAppointments}
              referrerPath="/tasks/requests"
            />
          </div>
        ) : (
          <div className={`${BASE_CLASS}__fullscreen`}>
            <LoadingIndicator loading={appointmentsLoading} onFullPage>
              <EmptyLayout
                message={t('general.error_messages.no_records_found')}
                imgSrc=""
              />
            </LoadingIndicator>
          </div>
        )}
      </AdbLayout.Content>
    </div>
  );
};

export default Requests;
