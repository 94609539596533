import ErrorField from '@smart/components-adb/atoms/ErrorField/ErrorField';
import SearchFilterPanel from '@smart/components-adb/atoms/SearchFilterPanel/SearchFilterPanel';
import AdbSearchInput from '@smart/components-adb/molecules/AdbSearchInput/AdbSearchInput';
import FilterDropdown from '@smart/components-adb/molecules/FilterDropdown/FilterDropdown';
import {
  Button,
  IconButton,
  Spinner,
  TextInput,
} from '@smart/react-components';
import { CustomerSearchType } from '@store/customers/defaults';
import { FacetSortByValue } from '@ui/data-models/facet/facet.model';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import debounce from 'lodash.debounce';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import type { CustomerSearchHeaderProps } from './TableHeader.config';

const BASE_CLASS = 'adb-customer-search';

const Header = ({
  currentSearchType,
  onSearchClick,
  searchGlobalQuery,
  searchDmsQuery,
  globalFilter,
  setGlobalFilter,
  onSearchTypeChange,
  isTestDrivePage,
  showGlobalSearchInputError,
  onRefresh,
  rows,
  isRefreshing,
}: CustomerSearchHeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [params, setParams] = useSearchParams();
  const searchQuery = params.get('search') ?? '';

  const superLightActive = useFeatureFlag({
    key: 'use-superlight',
    defaultValue: false,
  });
  const isDmsSearchEnabled = useFeatureFlag({
    key: 'use-dms-search',
    defaultValue: false,
  });

  useEffect(() => {
    setGlobalFilter(searchQuery);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleFilterClick = (filter: string): void => {
    onSearchTypeChange(filter as CustomerSearchType);
  };

  const getFormattedFiltersWithTranslation = (
    filters: Record<string, any>
  ): FacetSortByValue[] =>
    Object.values(filters)
      .filter((item) => {
        if (item === CustomerSearchType.DMS) {
          return isDmsSearchEnabled;
        }

        return true;
      })
      .map((item) => ({
        displayName: t(
          `customer.search.buttons.${item.toLowerCase().replace(' ', '_')}`
        ),
        queryValue: item,
      }));

  const formattedFilters =
    getFormattedFiltersWithTranslation(CustomerSearchType);
  const defaultFilter = formattedFilters[0];

  const getPathNewCustomer = () => {
    if (isTestDrivePage) {
      return superLightActive
        ? '/test-drive/confirmation/user-details/new-light-customer'
        : '/test-drive/confirmation/user-details/new-customer';
    }
    return '/customer/register';
  };

  const debouncedSetParams = useMemo(
    () => debounce((val) => setParams(val), 500),
    [setParams]
  );

  const onChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setGlobalFilter(event.target.value);
    debouncedSetParams({ search: event.target.value });
  };

  return (
    <SearchFilterPanel
      searchBar={
        <>
          {currentSearchType === CustomerSearchType.LOCAL && (
            <TextInput
              id="table_search"
              value={globalFilter}
              name="search_local"
              onChange={onChange}
              label={t('customer.search.enter_mail_name')}
              caption={`${rows} ${t('general.labels.results')}`}
              type="text"
            />
          )}
          {currentSearchType === CustomerSearchType.GLOBAL && (
            <AdbSearchInput
              value={searchGlobalQuery}
              label={t('customer.search.enter_mail')}
              onSearch={(q) => onSearchClick(q)}
            />
          )}
          {currentSearchType === CustomerSearchType.DMS && (
            <AdbSearchInput
              value={searchDmsQuery}
              label={t('customer.search.enter_string')}
              onSearch={(q) => onSearchClick(q)}
            />
          )}
          {showGlobalSearchInputError && (
            <ErrorField
              errorMsg={`${t('general.error_messages.validations.invalid')} ${t(
                'form_fields.basic.email'
              )}`}
            />
          )}
        </>
      }
      actionBtns={
        <>
          <IconButton
            aria-label="Add customer"
            mode={200}
            variant="secondary"
            onClick={() => navigate(getPathNewCustomer())}
            className={`${BASE_CLASS}__new-customer-icon`}
          >
            <Button.Icon icon="new-customer" aria-label="new-customer" />
          </IconButton>

          <Button
            mode={200}
            type="button"
            variant="secondary"
            onClick={() => navigate(getPathNewCustomer())}
            className={`${BASE_CLASS}__new-customer-text`}
          >
            {t('customer.search.buttons.new_customer')}
          </Button>

          <FilterDropdown
            label={`${t('customer.search.buttons.search')}: `}
            items={formattedFilters}
            onSelect={(filter) =>
              handleFilterClick(filter?.queryValue ?? defaultFilter.queryValue)
            }
            selectedFilter={{
              queryValue: currentSearchType,
              displayName: t(
                `customer.search.buttons.${currentSearchType
                  .toLowerCase()
                  .replace(' ', '_')}`
              ),
            }}
            defaultSelected={defaultFilter}
            showSelectedFilter
          />
        </>
      }
      filters={
        currentSearchType === CustomerSearchType.LOCAL && (
          <div className={`${BASE_CLASS}__refresh-btn`}>
            <Button
              onClick={onRefresh}
              variant="ghost"
              mode={200}
              className={`${BASE_CLASS}__refresh-text`}
              disabled={isRefreshing}
            >
              {isRefreshing ? (
                <Spinner size="md" />
              ) : (
                <Button.Icon icon="refresh" />
              )}
              {t('customer.search.buttons.refresh')}
            </Button>
          </div>
        )
      }
    />
  );
};

export default Header;
