import { useTranslation } from 'react-i18next';
import { date } from 'yup';
import { MarketCode } from '../helpers/utils';

export const RegistrationDateSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();
  let schemaObject = {};
  const today = new Date();
  today.setHours(0, 0, 0, 0);

  if (validationMarket) {
    switch (validationMarket) {
      default:
        schemaObject = {
          registrationDate: required
            ? date()
                .max(
                  today,
                  `${t('general.error_messages.validations.invalid')} ${t(
                    'form_fields.outlet.registration_date'
                  )}`
                )
                .required(
                  `${t('form_fields.outlet.registration_date')} ${t(
                    'general.error_messages.validations.is_required'
                  )}`
                )
            : date(),
        };
        break;
    }
  }

  return schemaObject;
};
