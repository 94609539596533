import { ObjectSchema, SchemaObjectDescription } from 'yup';

export const getFieldLabel = (
  schema?: ObjectSchema<any>,
  name?: string,
  label?: React.ReactNode
): string => {
  if (!label) return '';
  if (!name || !schema) return String(label);

  const fieldDescription = schema.describe().fields[
    name
  ] as SchemaObjectDescription;

  if (!fieldDescription) {
    return String(label);
  }

  const isRequired = !fieldDescription.optional && !fieldDescription.nullable;
  return isRequired ? `${label} *` : String(label);
};

export type AdbSchemaProps = {
  schema?: ObjectSchema<any>;
};
