import {
  Button,
  Dialog,
  Drawer,
  Flex,
  IconButton,
  Link,
} from '@smart/react-components';
import { setHasFilterChanged } from '@store/calendar/setting';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCalendarContext } from '../../../../contexts/calendar-context';
import CalendarAccordion from '../CalendarAccordion/CalendarAccordion';
import { SidebarProps } from '../Sidebar.config';
import './SideDrawer.scss';

const SideDrawer = ({ onCreateAppointment }: SidebarProps) => {
  const { t } = useTranslation();
  const { dispatch, hasFilterChanged } = useCalendarContext();

  const [currentAccordion, setCurrentAccordion] = useState<'expert' | 'car'>(
    'expert'
  );
  const [isDrawerOpen, setIsDrawerOpen] = useState<boolean>(false);

  const toggleDemoCarFilter = () => {
    setCurrentAccordion(currentAccordion === 'car' ? 'expert' : 'car');
    setHasFilterChanged(dispatch, !hasFilterChanged);
  };

  return (
    <>
      <Drawer
        title="Mobile menu"
        trigger={
          <div className="mobile-hamburger-menu">
            <IconButton
              mode={300}
              variant="primary"
              onClick={() => setIsDrawerOpen(true)}
            >
              <IconButton.Icon icon="burger-menu" />
            </IconButton>
          </div>
        }
        open={isDrawerOpen}
        position="left"
      >
        <Flex
          direction="column"
          height="full"
          padding={400}
          justifyContent="space-between"
        >
          <Dialog.Text style={{ height: '100%' }}>
            <Link>
              <Button onClick={toggleDemoCarFilter}>
                <Button.Icon icon="calendar" />
                {currentAccordion === 'expert'
                  ? t('feature_calendar.general.demo_vehicles')
                  : t('feature_calendar.general.smart_expert')}
              </Button>
            </Link>
            <CalendarAccordion
              setIsDrawerOpen={setIsDrawerOpen}
              currentAccordion={currentAccordion}
            />
          </Dialog.Text>
          <Dialog.Close asChild onClick={() => setIsDrawerOpen(false)}>
            <Button mode={300} variant="secondary">
              {t('feature_calendar.general.buttons.close')}
            </Button>
          </Dialog.Close>
        </Flex>
      </Drawer>
      <div className="mobile-new-appointment">
        <Button
          mode={200}
          type="button"
          variant="primary"
          onClick={onCreateAppointment}
        >
          <Button.Icon icon="plus" />
          {t('feature_calendar.general.appointment')}
        </Button>
      </div>
    </>
  );
};

export default SideDrawer;
