import { CarStatus, ExtendedCar, McsAgent } from '@smart/adb-shared';
import { useModal } from '@smart/components-adb/molecules/Modal';
import { Accordion, Button, Text } from '@smart/react-components';

import AdbDynamicImage from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage';
import { imageConfig1To1 } from '@smart/components-adb/molecules/AdbDynamicImage/AdbDynamicImage.config';
import { setCurrentDemoCar } from '@store/calendar/demo-car';
import { setCurrentExpert } from '@store/calendar/expert';
import { useAgentContext } from 'contexts/agent-context';
import { useOutletAgentsQuery } from 'graphql/queries/outlet.generated';
import { useCallback, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import DemoCarsAvailabilityDialog from '../AvailabilityDialog/AvailabilityDialog';

import { useCalendarContext } from '../../../../contexts/calendar-context';
import { BASE_CLASS } from '../../config';
import type { CalendarAccordionProps } from './CalendarAccordion.config';

const BASE_CLASS_SIDEBAR = `${BASE_CLASS}__sidebar`;

const CalendarAccordion = ({
  setIsDrawerOpen,
  currentAccordion,
}: CalendarAccordionProps) => {
  const { t } = useTranslation();
  const {
    experts,
    dispatch,
    currentExpert,
    selectedCarIndex,
    demoCars,
    outlet,
  } = useCalendarContext();
  const { agent: loggedInExpert } = useAgentContext();

  const { registerModal } = useModal();

  const { data: agentsData, loading: agentsDataLoading } = useOutletAgentsQuery(
    {
      variables: {
        outletId: outlet?.mcsId ?? '',
      },
      skip: !outlet?.mcsId,
    }
  );

  const ALL_EXPERTS_OPTION = useRef({
    id: 'All',
    email: '',
    firstName: t('feature_calendar.general.all_experts'),
    lastName: '',
    outletId: '',
    createdAt: '',
    updatedAt: '',
    expertId: '',
    isActive: true,
  });

  const selectedCar = demoCars[selectedCarIndex];

  const getSelectedDemoCar = (carId: string): string =>
    carId === selectedCar?.vin ? 'selected' : '';

  const shouldShowExpertsAccordion =
    currentAccordion === 'expert' && !!experts && experts.length > 0;
  const shouldShowDemoCarAccordion =
    currentAccordion === 'car' && !!demoCars && demoCars.length > 0;

  /**
   * Content fragments
   * ----------------------------------------
   */

  const handleSetCurrentExpert = useCallback(
    (expert?: McsAgent) => {
      if (expert && expert.gigyaId !== currentExpert?.gigyaId) {
        setCurrentExpert(dispatch, expert as McsAgent);
      } else {
        setCurrentExpert(dispatch, ALL_EXPERTS_OPTION.current);
      }
    },
    [currentExpert?.gigyaId, dispatch]
  );

  useEffect(() => {
    const loggedInExpertAsMcsAgent = agentsData?.outletAgents.find(
      (a) => a.gigyaId === loggedInExpert?.id
    );

    const expert =
      (currentExpert || loggedInExpertAsMcsAgent) ?? ALL_EXPERTS_OPTION.current;

    setCurrentExpert(dispatch, expert);
  }, [
    dispatch,
    agentsDataLoading,
    agentsData?.outletAgents,
    loggedInExpert?.id,
    currentExpert,
  ]);

  // list of demo cars in accordion
  const showDemoCars = (demoCarsData: ExtendedCar[]) =>
    demoCarsData?.map((demoCar, i) => {
      const handleSetCurrentDemoCar = () => setCurrentDemoCar(dispatch, i);

      return (
        <a
          key={demoCar?.carId}
          onClick={handleSetCurrentDemoCar}
          onKeyDown={handleSetCurrentDemoCar}
          role="button"
          tabIndex={0}
        >
          <Text
            key={demoCar?.carId}
            variant="cap-200"
            as="p"
            className={`${BASE_CLASS_SIDEBAR}-demo-cars-demo-car ${demoCar.status.toLowerCase()} ${getSelectedDemoCar(
              demoCar.carId
            )}`}
          >
            {demoCar?.licensePlateNumber}
          </Text>
        </a>
      );
    });

  /**
   * @returns CalendarAccordions
   * ----------------------------------------
   */

  const carActive = selectedCar?.status === CarStatus.Active;
  return (
    <>
      {shouldShowExpertsAccordion && (
        <div className={`${BASE_CLASS_SIDEBAR}-experts`}>
          <Accordion type="single" value={currentAccordion}>
            <Accordion.Item
              value="expert"
              title={`${t('feature_calendar.general.smart_expert')}`}
            >
              <div className={`${BASE_CLASS_SIDEBAR}-experts-accordion`}>
                <a
                  onClick={() => handleSetCurrentExpert()}
                  onKeyDown={() => handleSetCurrentExpert()}
                  role="button"
                  tabIndex={0}
                >
                  <Text
                    variant="cap-200"
                    as="p"
                    className={`${BASE_CLASS_SIDEBAR}-experts-expert ${
                      currentExpert?.id === 'All' ? 'selected' : ''
                    }`}
                  >
                    {t('feature_calendar.general.all_experts')}
                  </Text>
                </a>
                {experts &&
                  experts.map((expert: McsAgent) => (
                    <a
                      onClick={() => handleSetCurrentExpert(expert)}
                      key={expert.id}
                      onKeyDown={() => handleSetCurrentExpert(expert)}
                      role="button"
                      tabIndex={0}
                    >
                      <Text
                        key={expert.id}
                        variant="cap-200"
                        as="p"
                        className={`${BASE_CLASS_SIDEBAR}-experts-expert ${
                          expert.id === currentExpert?.id ? 'selected' : ''
                        }`}
                      >
                        {`${expert.firstName} ${expert.lastName}`}
                      </Text>
                    </a>
                  ))}
              </div>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      {shouldShowDemoCarAccordion && (
        <div className={`${BASE_CLASS_SIDEBAR}-demo-cars`}>
          <Accordion type="single" value={currentAccordion}>
            <Accordion.Item
              value="car"
              title={t('feature_calendar.general.demo_vehicles')}
            >
              <div className={`${BASE_CLASS_SIDEBAR}-demo-cars-accordion`}>
                {showDemoCars(demoCars)}
              </div>
            </Accordion.Item>
          </Accordion>
        </div>
      )}
      {currentAccordion === 'car' && (
        <div className={`${BASE_CLASS_SIDEBAR}__car-details`}>
          <AdbDynamicImage
            imageUrl={selectedCar?.image ?? ''}
            imageAltText={selectedCar?.name ?? ''}
            imageConfig={imageConfig1To1}
            className={`${BASE_CLASS_SIDEBAR}-demo-cars-image`}
          />
          <div className={`${BASE_CLASS_SIDEBAR}__car-details-name`}>
            <Text variant="p-100" as="p">
              {`${selectedCar?.name ?? ''}${selectedCar?.line ?? ''}${selectedCar?.features?.exterior ?? ''}${selectedCar?.features?.interior ?? ''}`}
            </Text>
          </div>
          <Button
            variant="secondary"
            mode={200}
            onClick={() => {
              setIsDrawerOpen?.(false);
              registerModal(
                <DemoCarsAvailabilityDialog currentDemoCar={selectedCar} />
              );
            }}
            className={`${BASE_CLASS_SIDEBAR}-demo-cars-image-action`}
          >
            <Button.Icon
              icon={carActive ? 'alert' : 'status-good'}
              aria-label={carActive ? 'alert' : 'status-good'}
            />
            {carActive
              ? `${t('feature_calendar.general.buttons.deactivate')}`
              : `${t('feature_calendar.general.buttons.activate')}`}
          </Button>
        </div>
      )}
    </>
  );
};

export default CalendarAccordion;
