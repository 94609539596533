import { CalendarModalProps } from '@store/calendar/types';
import { CalendarContextProvider } from '../../contexts/calendar-context';
import AdbDialog from '../molecules/AdbDialog/AdbDialog';
import Appointments from './Appointments/Appointments';
import { currentDate } from './Locales/Locales';
import { BASE_CLASS } from './config';
import './index.scss';

const DIALOG_CLASS = 'adb-calendar-modal';

const CalendarModal = (props: CalendarModalProps) => (
  <AdbDialog id={DIALOG_CLASS} size="maximized">
    <AdbDialog.Content>
      <div className={BASE_CLASS}>
        <Appointments currentDate={currentDate} {...props} />
      </div>
    </AdbDialog.Content>
  </AdbDialog>
);

const CalendarModalWrapper = (props: CalendarModalProps) => (
  <CalendarContextProvider>
    <CalendarModal {...props} />
  </CalendarContextProvider>
);

export default CalendarModalWrapper;
