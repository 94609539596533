import { Icon } from '@smart/react-components';
import classNames from 'classnames';
import { useState } from 'react';
import AdbIcon from '../../atoms/AdbIcon/AdbIcon';
import { IconBackgroundSize } from '../../atoms/AdbIcon/AdbIcon.config';
import type { AccordionItemProps } from './AccordionItem.config';
import './AccordionItem.scss';

const BASE_CLASS = 'adb-accordion-item';

const AccordionItem = ({
  itemId,
  icon,
  isOpen,
  grid,
  children,
  accordionDetail,
  hasBorder,
  hasDivider,
  hasItemBorder,
}: AccordionItemProps) => {
  const [open, setOpen] = useState(isOpen);

  const chevron = open ? 'chevron-up' : 'chevron-down';

  const onClick = () => {
    setOpen(!open);
  };

  const onKeyDown = () => {};

  return (
    <div
      className={classNames(BASE_CLASS, {
        [`${BASE_CLASS}--border`]: hasBorder,
        [`${BASE_CLASS}--divider`]: hasDivider,
        [`${BASE_CLASS}--closed`]: !open,
      })}
    >
      <div className={`${BASE_CLASS}__item`}>
        <div className={`${BASE_CLASS}__item-icon`}>
          <AdbIcon
            icon={icon.icon}
            color={icon.color}
            backgroundColor={icon.backgroundColor}
            disabled={icon.disabled}
            backgroundSize={IconBackgroundSize.MEDIUM}
            iconHasBorder={icon.iconHasBorder}
          />
        </div>
        <div>
          <div
            className={`${BASE_CLASS}__item-header`}
            style={{
              gridTemplateColumns: `${grid} 1fr`,
            }}
            role="button"
            onClick={onClick}
            onKeyDown={onKeyDown}
            tabIndex={0}
            data-testid="header"
          >
            {children}
            {itemId && <Icon aria-label="Required Aria Label" icon={chevron} />}
          </div>
          {!!open && <div data-testid="item-details">{accordionDetail}</div>}
          {hasItemBorder && <div className={`${BASE_CLASS}__item-border`} />}
        </div>
      </div>
    </div>
  );
};

export default AccordionItem;
