import { Appointment, AppointmentType } from '@smart/adb-shared';
import EntryPoint from '@smart/components-adb/atoms/EntryPoint/EntryPoint';
import { useModal } from '@smart/components-adb/molecules/Modal';
import SmartComLinkOut from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut';
import {
  LinkOutConfig,
  buildLinkOutConfig,
} from '@smart/components-adb/molecules/SmartComLinkOut/SmartComLinkOut.config';
import Feedback from '@smart/components-adb/organisms/Feedback/Feedback';
import { OneTrustCookieButton } from '@smart/components-adb/organisms/OneTrustCookieButton/OneTrustCookieButton';
import Requests from '@smart/components-adb/organisms/Requests/Requests';
import {
  getDateXMonthsAfter,
  getDateXMonthsBefore,
  getIsFutureTime,
  getWeekEndDate,
  getWeekStartDate,
} from '@ui/library/helpers/date';
import { useFeatureFlag } from '@utils/configs/featureFlag';
import { getCustomerRoute } from '@utils/helpers/route';
import { useAgentContext } from 'contexts/agent-context';
import { useAuthContext } from 'contexts/auth-context';
import { useLanguageContext } from 'contexts/language-context';
import { useMarketContext } from 'contexts/market-context';
import { useAllAppointmentsQuery } from 'graphql/queries/appointments.generated';
import { useAllTasksSimpleQuery } from 'graphql/queries/tasks.generated';
import { useCurrentOutlet } from 'hooks/outlet';
import { useScreenSize } from 'hooks/useScreenSize';
import AdbLayout from 'layouts/spacing-layout/AdbLayout';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import YourTasks from '../components/organisms/YourTasks/YourTasks';
import Appointments from './dashboard/Appointments/Appointments';
import { ConfiguratorPromptModal } from './dashboard/ConfiguratorPromptModal/ConfiguratorPromptModal';
import { getEntryPoints } from './dashboard/Dashboard.config';
import NextAppointment from './dashboard/NextAppointment/NextAppointment';
import './index.scss';
import {
  filterCalenderWeekAppointmensByExpertId,
  filterNonServiceAppointments,
  filterOpenAppointments,
  filterOpenTasks,
  filterYourAppointments,
  mapAppointments,
  mapTasks,
  sortAndSlice,
} from './tasks/config';

const BASE_CLASS = 'adb-dashboard';

const HomePage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const outlet = useCurrentOutlet();
  const { isMobile } = useScreenSize();
  const { agent } = useAgentContext();
  const { market: selectedAppMarket } = useMarketContext();
  const { locale } = useLanguageContext();
  const { auth } = useAuthContext();
  const { registerModal } = useModal();
  const entryPoints = getEntryPoints(t);

  const selectedAgentId = agent?.id;

  const { data: tasks, loading: tasksLoading } = useAllTasksSimpleQuery({
    variables: {
      input: {
        outletId: outlet?.mcsId,
      },
    },
    skip: !outlet?.mcsId,
  });

  const { data: appointments, loading: appointmentsLoading } =
    useAllAppointmentsQuery({
      variables: {
        input: {
          endDateTime: getDateXMonthsAfter(2).toISOString(),
          outletId: agent?.outletId ?? '',
          startDateTime: getDateXMonthsBefore(2).toISOString(),
        },
      },
      skip: !agent?.outletId,
    });

  const {
    data: calendarWeekAppointments,
    loading: calendarWeekAppointmentsLoading,
  } = useAllAppointmentsQuery({
    variables: {
      input: {
        endDateTime: getWeekEndDate().toISOString(),
        outletId: agent?.outletId ?? '',
        startDateTime: getWeekStartDate().toISOString(),
      },
    },
    fetchPolicy: 'cache-and-network',
    skip: !agent?.outletId,
  });

  const allTasks = tasks?.allTasks.tasks ?? [];
  const openTasks = filterOpenTasks(allTasks);

  const openAppointments = filterOpenAppointments(
    appointments?.allAppointments.appointments
  );

  const nonServiceAppointments = filterNonServiceAppointments(
    calendarWeekAppointments?.allAppointments.appointments
  );

  const calendarWeekAppointmentsByExpertId =
    filterCalenderWeekAppointmensByExpertId(
      selectedAgentId,
      nonServiceAppointments
    );

  const referrerPath = useLocation().pathname;

  const linkOutConfig: LinkOutConfig = buildLinkOutConfig({
    language: locale,
    market: selectedAppMarket ?? '',
    outletId: agent?.outletId,
    referrerPath,
  });

  const shouldDisableLinkOut = (
    disabled: boolean | undefined,
    redirectPath?: string
  ): boolean =>
    disabled ||
    !locale ||
    !selectedAppMarket ||
    !redirectPath ||
    !agent?.outletId;

  const getNextAppointmentTitle = (appointment?: Appointment) => {
    const title = appointment?.title ?? '';

    const carId =
      appointment?.type === AppointmentType.Handover
        ? appointment?.car?.carId
        : appointment?.car?.licensePlate;

    return [t(title), carId].join(' : ');
  };

  const nextAppointment: Appointment | undefined =
    calendarWeekAppointmentsByExpertId.find((appointment) =>
      getIsFutureTime(new Date(appointment.start))
    );

  const openNextCustomer = () => {
    if (nextAppointment?.customer) {
      navigate(getCustomerRoute(nextAppointment.customer));
    }
  };

  const openRequests = [
    ...mapTasks(openTasks),
    ...mapAppointments(openAppointments),
  ];

  const yourTasks = sortAndSlice(
    [
      ...mapTasks(
        allTasks.filter((et) => et.agent?.gigyaId === selectedAgentId)
      ),
      ...mapAppointments(
        filterYourAppointments(
          appointments?.allAppointments.appointments ?? [],
          selectedAgentId
        )
      ),
    ],
    true
  );

  const useLeadFeatures = useFeatureFlag({
    defaultValue: false,
    key: 'use-lead-features',
  });

  const renderEntryPoints = () => (
    <div className={`${BASE_CLASS}__entry-points`}>
      {entryPoints.map((entryPoint) =>
        entryPoint.isLinkOut ? (
          <SmartComLinkOut
            redirectPath={entryPoint.redirectPath}
            config={{
              ...linkOutConfig,
              ...(entryPoint.isLinkOut && {
                accessToken: auth ? auth['access-token'] : undefined,
                idToken: auth ? auth['id-token'] : undefined,
                refreshToken: auth ? auth['refresh-token'] : undefined,
              }),
            }}
            disabled={shouldDisableLinkOut(
              entryPoint.disabled,
              entryPoint.redirectPath
            )}
            key={entryPoint.name}
          >
            <EntryPoint
              {...{
                ...entryPoint,
                disabled: shouldDisableLinkOut(
                  entryPoint.disabled,
                  entryPoint.redirectPath
                ),
              }}
              reduceMargin
            />
          </SmartComLinkOut>
        ) : (
          <EntryPoint
            {...entryPoint}
            {...(entryPoint.id === 'customizer' && {
              onClick: () => registerModal(<ConfiguratorPromptModal />),
            })}
            key={entryPoint.name}
            reduceMargin
            isClickable
          />
        )
      )}
    </div>
  );

  const renderMobileDashboard = () => (
    <main className={BASE_CLASS}>
      <AdbLayout.Content>
        <div className={`${BASE_CLASS}__next-appointment`}>
          <NextAppointment
            start={new Date(nextAppointment?.start ?? '')}
            end={new Date(nextAppointment?.end ?? '')}
            name={`${nextAppointment?.customer?.firstName} ${nextAppointment?.customer?.lastName}`}
            title={getNextAppointmentTitle(nextAppointment)}
            loading={calendarWeekAppointmentsLoading}
            exists={!!nextAppointment}
            onClick={openNextCustomer}
          />
        </div>
        {renderEntryPoints()}
        <div className={`${BASE_CLASS}__content`}>
          <div className={`${BASE_CLASS}__content-requests`}>
            <Requests
              requests={openRequests}
              title={t('dashboard.open_requests')}
              link={{
                text: t('general.labels.view_all'),
                path: useLeadFeatures ? '/tasks' : '/tasks/requests',
              }}
              loading={appointmentsLoading}
            />
          </div>

          <div className={`${BASE_CLASS}__content-tasks`}>
            <YourTasks
              title={t('dashboard.your_tasks')}
              link={{
                text: t('general.labels.view_all'),
                path: useLeadFeatures ? '/tasks' : '/tasks/todos',
              }}
              tasks={yourTasks}
              loading={appointmentsLoading || tasksLoading}
            />
          </div>

          <div className={`${BASE_CLASS}__content-appointments`}>
            <Appointments
              appointments={calendarWeekAppointmentsByExpertId}
              loading={calendarWeekAppointmentsLoading}
            />
          </div>
        </div>
      </AdbLayout.Content>
    </main>
  );

  const renderDashboard = () => (
    <AdbLayout.Content>
      <main className={BASE_CLASS}>
        {renderEntryPoints()}
        <NextAppointment
          start={new Date(nextAppointment?.start ?? '')}
          end={new Date(nextAppointment?.end ?? '')}
          name={`${nextAppointment?.customer?.firstName} ${nextAppointment?.customer?.lastName}`}
          title={getNextAppointmentTitle(nextAppointment)}
          loading={calendarWeekAppointmentsLoading}
          exists={!!nextAppointment}
          onClick={openNextCustomer}
        />

        <Requests
          requests={openRequests}
          title={t('dashboard.open_requests')}
          link={{
            text: t('general.labels.view_all'),
            path: useLeadFeatures ? '/tasks' : '/tasks/requests',
          }}
          loading={appointmentsLoading}
        />

        <YourTasks
          title={t('dashboard.your_tasks')}
          link={{
            text: t('general.labels.view_all'),
            path: useLeadFeatures ? '/tasks' : '/tasks/todos',
          }}
          tasks={yourTasks}
          loading={appointmentsLoading || tasksLoading}
        />
        <Appointments
          appointments={calendarWeekAppointmentsByExpertId}
          loading={calendarWeekAppointmentsLoading}
        />
      </main>
    </AdbLayout.Content>
  );

  return (
    <>
      {isMobile ? renderMobileDashboard() : renderDashboard()}
      <div className={`${BASE_CLASS}__overlay-buttons`}>
        <Feedback />
        <OneTrustCookieButton />
      </div>
    </>
  );
};

export default HomePage;
