import { Button, Link } from '@smart/react-components';
import { setHasFilterChanged } from '@store/calendar/setting';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useCalendarContext } from '../../../contexts/calendar-context';
import { BASE_CLASS } from '../config';
import CalendarAccordion from './CalendarAccordion/CalendarAccordion';
import { SidebarProps } from './Sidebar.config';
import './Sidebar.scss';

const BASE_CLASS_SIDEBAR = `${BASE_CLASS}__sidebar`;

const Sidebar = ({ onCreateAppointment }: SidebarProps) => {
  const { t } = useTranslation();
  const { dispatch, hasFilterChanged } = useCalendarContext();

  const [currentAccordion, setCurrentAccordion] = useState<'expert' | 'car'>(
    'expert'
  );

  const toggleDemoCarFilter = () => {
    setCurrentAccordion(currentAccordion === 'car' ? 'expert' : 'car');
    setHasFilterChanged(dispatch, !hasFilterChanged);
  };

  return (
    <div className={BASE_CLASS_SIDEBAR}>
      <div className={`${BASE_CLASS_SIDEBAR}-new-appointment`}>
        <Button
          mode={200}
          type="button"
          variant="primary"
          onClick={onCreateAppointment}
        >
          <Button.Icon icon="plus" />
          {t('feature_calendar.general.appointment')}
        </Button>
      </div>
      <div className={`${BASE_CLASS_SIDEBAR}-filter-header`}>
        <Link asChild>
          <Button onClick={toggleDemoCarFilter}>
            <Button.Icon icon="calendar" />
            {currentAccordion === 'expert'
              ? t('feature_calendar.general.demo_vehicles')
              : t('feature_calendar.general.smart_expert')}
          </Button>
        </Link>
      </div>
      <CalendarAccordion currentAccordion={currentAccordion} />
    </div>
  );
};

export default Sidebar;
