import { AppointmentType } from '@smart/adb-shared';
import {
  AdbIconProps,
  IconBackground,
  IconColor,
} from '@smart/components-adb/atoms/AdbIcon/AdbIcon.config';
import { TabId } from '../tasks/utils';
/**
 * getIcon
 * @param type
 * return iconProps for task
 */
export const getIconFromType = (type: AppointmentType): AdbIconProps => {
  switch (type) {
    case AppointmentType.TestDrive: {
      return {
        icon: 'test-drive',
        color: IconColor.WHITE,
        backgroundColor: IconBackground.BLACK,
      };
    }
    case AppointmentType.Handover: {
      return {
        icon: 'car',
        color: IconColor.WHITE,
        backgroundColor: IconBackground.BLACK,
      };
    }
    default:
      return {
        icon: 'watch-wish-list',
        color: IconColor.WHITE,
        backgroundColor: IconBackground.BLACK,
      };
  }
};
/**
 * DefaultOpeningDateState
 * type for default opening date state
 */
export interface DefaultOpeningDateState {
  idToBeExpanded: string;
  initialTab?: TabId;
}

export const scrollToPosition = (
  ref: React.RefObject<HTMLDivElement>
): void => {
  if (ref.current) {
    ref.current.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  }
};
