import { useTranslation } from 'react-i18next';
import { string } from 'yup';
import { GetMaxLengthValidationMsg, MarketCode } from '../helpers/utils';

export const FirstNameSchema = (
  validationMarket: MarketCode,
  required = false
) => {
  const { t } = useTranslation();

  const firstNameRegex =
    /^[^-\s¡?÷¿\\ˆ~!@#$%^&*()_+=[\]{};:"|/,.<>?¢0123456789]{2,20}([' -]{1}[^-\s¡?÷¿\\ˆ~!@#$%^&*()_+=[\]{};:"|/,.<>?¢0123456789]{1,20}){0,2}$/g;

  if (validationMarket === 'de') {
    return {
      firstName: required
        ? string()
            .required(
              `${t('form_fields.basic.first_name')} ${t(
                'general.error_messages.validations.is_required'
              )}`
            )
            .matches(
              firstNameRegex,
              `${t('form_fields.basic.first_name')} ${t(
                'general.error_messages.validations.invalid'
              )}`
            )
            .max(
              40,
              GetMaxLengthValidationMsg(t('form_fields.basic.first_name'), 40)
            )
        : string()
            .matches(
              firstNameRegex,
              `${t('form_fields.basic.first_name')} ${t(
                'general.error_messages.validations.invalid'
              )}`
            )
            .max(
              40,
              GetMaxLengthValidationMsg(t('form_fields.basic.first_name'), 40)
            ),
    };
  }

  return {
    firstName: required
      ? string()
          .required(
            `${t('form_fields.basic.first_name')} ${t(
              'general.error_messages.validations.is_required'
            )}`
          )
          .matches(
            firstNameRegex,
            `${t('general.error_messages.validations.invalid')} ${t(
              'form_fields.basic.first_name'
            )} `
          )
          .max(
            40,
            GetMaxLengthValidationMsg(t('form_fields.basic.first_name'), 40)
          )
      : string()
          .matches(
            firstNameRegex,
            `${t('general.error_messages.validations.invalid')} ${t(
              'form_fields.basic.first_name'
            )} `
          )
          .max(
            40,
            GetMaxLengthValidationMsg(t('form_fields.basic.first_name'), 40)
          ),
  };
};
